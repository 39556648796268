import b1 from '@images/background.png';
import b2 from '@images/background2.png';
import b3 from '@images/background3.png';

const array = [
    b1,
    b2,
    b3,
]

const background = (v) => ({
    backgroundImage: `url(${array[v]})`,
    backgroundSize: v === 0 ? '500px' : v === 1 ? '200px' : 'auto',
})

const styles = (theme) => ({
    ...background(1),
    overflow: 'hidden',
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.body,
    lineHeight: theme.lineHeights.body,
    color: theme.colors.black,
    // background: 'linear-gradient(180deg, hsla(190, 89%, 89%, 0.4) 0%, hsla(190, 89%, 89%, 0.35) 18%, hsla(190, 89%, 89%, 0.25) 37%, hsla(190, 89%, 89%, 0.2) 58%, hsla(259, 84%, 93%, 0.2) 80%, hsla(287, 87%, 91%, 0.22) 90%, hsla(299, 89%, 89%, 0.3) 100%)',
    // background: 'linear-gradient(180deg,' + theme.colors.grass + ' 0%, ' + theme.colors.grass + ' 25%, ' + theme.colors.grass2 + ' 50%, ' + theme.colors.grass2 + ' 75%, ' + theme.colors.grass3 + ' 100%)',
    margin: 0,
    padding: 0,
    minHeight: '100vh',
    position: 'relative',
    '&:after': {
        display: 'block',
        content: '" "',
        background: '#6dbb4e78',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    }
})

export default styles