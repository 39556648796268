import breakpoints from './breakpoints';

const theme = {
    breakpoints,
    fontSizes: [
        8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96
    ],
    colors: {
        white: '#FFFFFF',
        black: '#333333',
        grayDarker: '#444444',
        lightBlue: '#47bcd6',
        orange: '#eb924d',
        orangeOpaque: '#e19962',
        yellow: '#eda942',
        turquoise: '#21af94',
        grass: '#b4d05f',
        grass2: '#a5be59',
        grass3: '#7d9734',
        grass4: '#728e21',
        grass5: '#627a1b',
        grass6: '#5a692e',
        grass7: '#748d2f',
        grassLight: '#dcdf84',
        candy: '#e0224e',
        whiteCandy: '#EEEEEE'
    },
    space: [
        0, 4, 8, 16, 32, 64, 128, 256
    ],
    fonts: {
        body: '"Baloo 2"',
        heading: '"Baloo 2"',
    },
    fontWeights: {
        body: 400,
        heading: 600,
        bold: 600,
        bolder: 900
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },
    variants: {
    },
    text: {
        fontFamily: 'body',
    },
    buttons: {
        card: {
            position: 'relative',
            display: 'inline-block',
            cursor: 'pointer',
            outline: 'none',
            verticalAlign: 'middle',
            textDecoration: 'none',
            fontSize: ['22px', '28px', '28px', 3],
            fontWeight: 'bold',
            lineHeight: '1em',
            py: '8px',
            px: 3,
            mt: '10px',
            fontFamily: 'body',
            color: 'white',
            bg: '#f86955',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: '#cd4835',
            transformStyle: 'preserve-3d',
            textShadow: '1px 1px 2px rgba(84, 47, 0, 0.4)',
            transform: 'translate3d(0, 0%, -1em)',
            transition: 'all 150ms cubic-bezier(0, 0, 0.58, 1) !important',
            borderRadius: '20px',
            overflow: 'visible !important',
            '&:before': {
                position: 'absolute',
                content: '" "',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                bg: '#f16450',
                borderRadius: 'inherit',
                boxShadow: '0 0 0 2px #ef4a32, 0 0.19em 0 0 #fb604bbf',
                transform: 'translate3d(0, 16%,-1em)',
                transition: 'all 150ms cubic-bezier(0, 0, 0.58, 1) !important',
                zIndex: 11,
            },
            '&:hover': {
                background: '#f16450',
                transform: 'translate3d(0, 8%, -1em)',
                '&:before': {
                    boxShadow: '0 0 0 2px #ef4a32, 0 0.15em 0 0 #fb604bbf',
                    transform: 'translate3d(0, 8%, -1em)'
                }
            }
        },
        trial: {
            position: 'relative',
            display: 'inline-block',
            cursor: 'pointer',
            outline: 'none',
            verticalAlign: 'middle',
            textDecoration: 'none',
            fontSize: [2, 2, 3, 4, 6, 6],
            fontWeight: 'bold',
            lineHeight: '1em',
            py: '8px',
            px: 3,
            fontFamily: 'body',
            color: 'white',
            bg: 'yellow',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: '#e39724',
            transformStyle: 'preserve-3d',
            textShadow: '1px 1px 2px rgba(84, 47, 0, 0.4)',
            transform: 'translate3d(0, 0%, -1em)',
            transition: 'all 150ms cubic-bezier(0, 0, 0.58, 1) !important',
            borderRadius: '20px',
            overflow: 'visible !important',
            '&:before': {
                position: 'absolute',
                content: '" "',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                bg: '#e8a643',
                borderRadius: 'inherit',
                boxShadow: '0 0 0 2px #d47200, 0 0.19em 0 0 #bb8811',
                transform: 'translate3d(0,20%,-1em)',
                transition: 'all 150ms cubic-bezier(0, 0, 0.58, 1) !important',
                zIndex: 11,
            },
            '&:hover': {
                background: '#e8a53f',
                transform: 'translate3d(0, 10%, -1em)',
                '&:before': {
                    boxShadow: '0 0 0 2px #d47200, 0 0.15em 0 0 #bb8811',
                    transform: 'translate3d(0, 10%, -1em)'
                }
            }
        }
    },
}

export default theme