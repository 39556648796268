const defaultMediaSizes = {
  ScreenXS: 366,
  ScreenS: 414,
  ScreenM: 564,
  ScreenL: 768,
  ScreenXL: 1024
}

const mediaSizesValues = Object.values(defaultMediaSizes)

const breakpoints = mediaSizesValues.map(
  (v) => `${v / 16}em`
)

export default breakpoints