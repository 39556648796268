import React, {useContext} from 'react';
import {Box} from "rebass";
import {animated, useSpring} from "react-spring";
import { Context } from "@state";
// import AppHeader from '@organisms/AppHeader'
// import Navmenu from '@molecules/Navmenu'

const LayoutMainContent = ({children}) => {
  const [state] = useContext(Context);
  const { openMenu } = state

  const { translateX } = useSpring({
    from: { translateX: 0 },
    translateX: openMenu ? 1 : 0,
    config: { mass: 4, tension: 600, friction: 100 },
  })

  return (
    <animated.div
      id="here"
      style={{
        translateX: translateX.to({
          range: [0, 1],
          output: [0, 240]
        })
          .to((x) => {
            return `${x}px`
          })
      }}
    >
      {/*<AppHeader>*/}
      {/*  <Navmenu />*/}
      {/*</AppHeader>*/}

      <Box sx={{
        position: 'relative',
        zIndex: 5
      }}>
        {children}
      </Box>
    </animated.div>
  );
};

export default LayoutMainContent;