import React from "react"

import { useSpring, animated } from 'react-spring'

import { Button } from "rebass"

const styles = (sx) => ({
	position: "relative",
	overflow: "hidden",
	opacity: 1,
	cursor: "pointer",
	zIndex: 10,
	transition: 'background 150ms cubic-bezier(0, 0, 0.58, 1) !important',
	...sx,
})

const TrialButton = ({
	children = "Submit",
	delay = 0,
	duration = 400,
	state = true,
	sx = {},
	id = '',
	buttonVariant = 'trial',
	disabled = null,
	onClick = null
}) => {

	const { y } = useSpring({
		from: { y: 0 },
		y: state ? 1 : 0,
		config: { duration: duration },
		delay: delay
	})
	const loadAnimation = {
		opacity: y.to({
			range: [0, 1],
			output: [0, 1],
		})
	}
	const wrapperStyles = styles(sx)

	return (
		<animated.div style={loadAnimation}>
			<Button
				id={id}
				disabled={disabled}		
				variant={buttonVariant}
				sx={wrapperStyles}
				onClick={onClick}
			>
				{children}
			</Button>
		</animated.div>
	)
}

export default TrialButton
