import React from 'react'
import "normalize.css"
import theme from '@theme/theme'
import styles from '@theme/styles';

import { ThemeProvider } from 'emotion-theming'
import { Box } from 'rebass'

// import SubMenu from '@molecules/SubMenu'
import LayoutMainContent from "@organisms/LayoutMainContent";

const globals = styles(theme)

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{...globals, overFlow: 'hidden'}}>
        {/*<SubMenu />*/}
        <LayoutMainContent>{children}</LayoutMainContent>
      </Box>
    </ThemeProvider >
  )
}

export default Layout

